import { Button, Checkbox, Col, Form, Input, Typography } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import { Header } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOC_store_token } from "../../core/custom-local-storage";
import { Authenticate } from "../../network/authApi";
import { setUserType } from "../../redux/userTypeSlice";
const { Title } = Typography;

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logining, setLogining] = useState(false)
  const onLogin = async (values) => {
    setLogining(true)

    const handleUserTypeChange = (newUserType) => {
      dispatch(setUserType(newUserType));
    };

    try {
      const response = await Authenticate(values.username, values.password)
      if (response.status == 200) {
        handleUserTypeChange(response.data.userType)
        setLogining(false)
        LOC_store_token(response.data)
        navigate('/')
      }
    } catch (error) {
      console.log("error in save user")
      console.log(error);
    }
    setLogining(false)
  };

  return (
    <Layout>
      <Header
        theme='light'
        style={{
          height: "75px",
          display: "flex",
          alignItems: "center",
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          paddingLeft: 10,
          paddingRight: 10,
          zIndex: 2,
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}
      >
        <Col span={4} style={{ paddingTop: '25px' }}>

        </Col>
        <Col span={16}></Col>
        <Col span={4} style={{ textAlign: 'end', paddingRight: '30px' }}>
          <a href="" style={{ textDecoration: 'none', color: '', fontSize: '15px' }}>Sign Up</a>
        </Col>
      </Header>
      <Content
        style={{
          position: 'fixed',
          padding: 24,
          margin: 0,
          minHeight: 500,
          top: 65,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'scroll',
          padding: "0 520px 200px 520px",
        }}
      >
        <Title style={{ display: 'flex', justifyContent: "center", margin: '50px' }}>Sign In</Title>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onLogin}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                type: "username",
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input
              style={{ height: "40px" }}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              style={{ height: "40px" }}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a href="">
              Forgot password?
            </a>
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button loading={logining} block="true" type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}
export default Login;
