import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CoreBackButton, CoreDatePicker, CoreInput, CoreUpdateButton } from '../../components/core-components';
import CoreNRCInput from '../../components/nrcInput/nrc-input';
import { validatePhoneNumber } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import { CustomPath } from '../../routes/custom-path';
import dayjs from 'dayjs';


export class DriverUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on_updating_loading: false,
      driverId : this.props.params.id,
      items: [
        {
          title: "Home",
        },
        {
          title: <Link to="/driverpage">{i18next.t("driver")}</Link>,
        },
        {
          title: i18next.t("driver.update"),
        },
      ],
    }
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    const id = this.props.params.id

    try {
      const response = await ApiInstance(Api.driver, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);
      const dateOfBirth = dayjs(response.dateOfBirth, "YYYY-MM-DD");

      console.log(response.dateOfBirth)
      console.log(dateOfBirth)

      let driver = { ...response };

      this.setState((prevState) => ({
        data: {driver}
      }));

      this.formRef.current.setFieldsValue(driver);
    } catch (error) { }
  };

  updateDriver = async (values) => {

    const { data, driverId } = this.state;

    this.setState({ on_updating_loading: true })

    const requestData = {
      "id": driverId,
      "uniqueId": values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "dateOfBirth" : values.dateOfBirth,
      "phoneNumber": values.phoneNumber,
      "address": values.address,
      "nrc": values.nrc,
      "gender": values.gender,
      "status": values.status,
      "accounts": [
        {
          "loginId": values.phoneNumber,
          "password": values.password,  //Re Check whether to account password?
          "accountType": "PHONE"
        }
      ],
    }
    console.log("data:", data)
    try {
      const response = await ApiInstance(Api.driver, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null);
      if (response.status == 200) {
        this.props.navigate(CustomPath.driver)
      }
    } catch (error) { }
    this.setState({ on_updating_loading: false })
  };

  handleDateChange = (date, dateString) => {
    if (this.formRef?.current) {
      this.formRef.current.setFieldsValue({ dateOfBirth: dateString });
    }
  };

  render() {
    const { on_updating_loading, data } = this.state;
    
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Breadcrumb items={this.state.items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("driver.update")}</h3>}
              bordered={false}
              extra={
                <CoreBackButton
                  path={CustomPath.driver}
                  type="primary"
                  text={i18next.t("back")}
                ></CoreBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.updateDriver}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("driver_unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("driver_unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="phoneNumber"
                      label={i18next.t("phone_number")}
                      rules={[
                        {required: true, validator: validatePhoneNumber }
                      ]}
                    >
                      <CoreInput size="large" placeholder="09xxxxxxx" />
                    </Form.Item>
                  </Col>  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="password"
                      label={i18next.t("password")}
                    >
                      <CoreInput
                        isPasswordInput={true}
                        size="large"
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="nrc"
                      label={i18next.t("nrc")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreNRCInput />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                     <CoreDatePicker handleDateChange={this.handleDateChange} value={data?.dateOfBirth}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                      <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item name="address" label={i18next.t("address")}>
                      <CoreInput
                        isTextareaInput={true}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <CoreUpdateButton loading={on_updating_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter(DriverUpdate));