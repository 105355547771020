import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage'
import userTypeSlice from './userTypeSlice'

const persistConfig = {
  key: 'edya-fuel-station-root',
  storage,
}

const rootReducer = combineReducers({
  userType: userTypeSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
})

const persistor = persistStore(store)
export { persistor };
export default store