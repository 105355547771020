import { createSlice } from '@reduxjs/toolkit';

const userTypeSlice = createSlice({
  name: 'userType',
  initialState: { value: null },
  reducers: {
    setUserType: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserType } = userTypeSlice.actions;
export default userTypeSlice.reducer;