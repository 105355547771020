import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Card, Col, Form, Radio, Row } from 'antd';
import dayjs from 'dayjs';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CoreBackButton, CoreCreateButton, CoreDatePicker, CoreInput } from '../../components/core-components';
import CoreNRCInput from '../../components/nrcInput/nrc-input';
import { validatePhoneNumber } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import { CustomPath } from '../../routes/custom-path';


export class DriverCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on_create_loading : false,
      companyId : 1,
      dateOfBirth: null,
      items: [
        {
          title: "Home",
        },
        {
          title: <Link to={CustomPath.driver}>{i18next.t("driver")}</Link>,
        },
        {
          title: i18next.t("driver.form"),
        },
      ],
    }
  }

  componentDidMount() {
    const date = dayjs();
    this.setState({ currentDate: date.subtract(18, 'year') })
  }

  saveDriver = async (values) => {
    const {dateOfBirth} = this.state;
    this.setState({
      on_create_loading: true
    })

    const requestData = {
      "uniqueId" : values.uniqueId,
      "firstName" : values.firstName,
      "lastName" : values.lastName,
      "dateOfBirth" : dateOfBirth,
      "password": values.password,
      "phoneNumber": values.phoneNumber,
      "address" : values.address,
      "nrc" : values.nrc,
      "gender" : values.gender,
      "status": values.status,
      "accounts" : [
        {
            "loginId" : values.phoneNumber,
            "password": values.password,
            "accountType": "PHONE"
        }
    ],
  }

    try {
      const response = await ApiInstance(
        Api.driver, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.props.navigate(CustomPath.driver)
      }
    } catch (error) {
      console.log("error in save user");
      console.log(error);
    }
    this.setState({
      on_create_loading: false
    })
  };

  handleDateChange = (date, dateString) => {
    this.setState({
      dateOfBirth:dateString
    })
  };


  render() {
    const { items, adminId, on_create_loading } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Breadcrumb items={this.state.items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("driver.form")}</h3>}
              bordered={false}
              extra={  
                <CoreBackButton
                  path={CustomPath.driver}
                  type="primary"
                  text={i18next.t("back")}
                ></CoreBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.saveDriver}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("driver_unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("driver_unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="phoneNumber"
                      label={i18next.t("phone_number")}
                      rules={[
                        {required: true, validator: validatePhoneNumber }
                      ]}
                    >
                      <CoreInput size="large" placeholder="09xxxxxxxxx" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="password"
                      label={i18next.t("password")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        isPasswordInput={true}
                        size="large"
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12  }}>
                    <Form.Item
                      name="nrc"
                      label={i18next.t("nrc")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreNRCInput />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                     <CoreDatePicker handleDateChange={this.handleDateChange}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12  }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                     <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12  }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                    >
                     <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item name="address" label={i18next.t("address")}>
                      <CoreInput
                        isTextareaInput={true}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <CoreCreateButton loading={on_create_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter(DriverCreate));