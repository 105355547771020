import { CheckOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, ConfigProvider, DatePicker, Input, InputNumber, Select } from "antd";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import Theme from "../core/Theme";
import Logo from "./assets/EDYA.jpeg";

const EdLogo = () => {
  return (
    <img style={{ height: '75px', width: '80px', marginLeft: '20px' }} src={Logo} alt='Logo' />
  )
}

export default EdLogo;

export const CreateButton = ({ path }) => {
  return <Link to={path}>
    <Button
      type="primary"
      icon={<PlusCircleFilled />}
      shape="round"
    >
      {i18next.t("create")}
    </Button>
  </Link>
}

export const CoreCreateButton = ({...props} ,loading) => {
  return (
    <>
      <Button type="primary" htmlType="submit" style={{ borderRadius: '20px' }} loading={loading} {...props}>{i18next.t("create")}</Button>
    </>
  )
}

export const CoreUpdateButton = ({ ...props }) => {
  return (<>
    <Button
      type="primary"
      shape="round"
      htmlType="submit"
      {...props}
    >
      {i18next.t("update")}
    </Button>
  </>)

}

export const MvAddButton = () => {
  return (
    <Button type="primary" icon={<PlusCircleFilled />} shape="round">
      Add
    </Button>
  );
};

export const MvResetButton = () => {
  return (
    <Button shape="round" style={{ marginRight: "20px" }}>
      Reset
    </Button>
  );
};

export const CoreBackButton = ({ path, icon, text, ...props }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(path);
  };

  return (
    <Button onClick={handleButtonClick} {...props} type="primary" icon={icon} shape="round">
      {i18next.t("back")}
    </Button>
  );
};


export const MvButton = ({ text }) => {
  return (
    <Button type="primary" shape="round" style={{ margin: "0.3em" }}>
      {text}
    </Button>
  );
};

export const MvCard = ({ title, text }) => {
  return (
    <Card
      style={{
        width: "300px",
        height: "150px",
        background: "lightgreen",
        display: "inline-block",
        margin: "20px",
      }}
    >
      <CheckOutlined style={{ fontSize: "1.4em" }} />
      <span
        style={{ fontSize: "1.4em", fontWeight: "bold", marginLeft: "0.4em" }}
      >
        {title}{" "}
      </span>
      <p style={{ fontSize: "1.4em" }}>{text}</p>
    </Card>
  );
}

export const CoreInput = ({ ...inputProps }) => {
  const { bgcolor } = inputProps;
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorPrimaryHover: '#f5f5f5',
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        Input: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        },
        InputNumber: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        }
      },
    }}>
      {
        inputProps.isPasswordInput ? <Input.Password size="large" {...inputProps} /> : inputProps.isNumberInput ? <InputNumber size="large" {...inputProps} /> :
          inputProps.isTextareaInput ? <Input.TextArea {...inputProps} /> : <Input {...inputProps} size="large" />
      }
    </ConfigProvider>
  )
}
export const CoreSelect = ({ children, ...selectProps }) => {
  const { bgcolor } = selectProps;
  return (
    <ConfigProvider theme={{
      token: {
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorBorder: '#fff',
        colorPrimary: 'rgb(128, 128, 128)',
        colorPrimaryHover: '#fff',
        // controlHeightLG: '40px',
      },
      components: {
        Select: {
          borderRadius: '9px',
          optionPadding: '4px 12px',
          optionSelectedBg: 'rgb(128, 128, 128)',
          optionSelectedColor: '#fff',
          optionHeight: 30
        }
      }
    }}>
      <Select {...selectProps} >{children}</Select>
    </ConfigProvider>
  )
}


export const CoreDatePicker = ({ handleDateChange, value, ...datepickerProps }) => {
  
  const { bgcolor } = datepickerProps;
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorPrimaryHover: '#fff',
        cellWidth: 36,
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        DatePicker: {
          colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
          activeShadow: '0 0 0 1px rgb(128, 128, 128)',
          borderRadius: '9px',
          activeBorderColor: '#fff'
        },
      }
    }}>
      <DatePicker style={{height:'40px', width:"100%"}} {...datepickerProps} placeholder={value==null?i18next.t('select_date'):value}
        onChange={handleDateChange}/>
    </ConfigProvider>
  )
}

export const CoreSearchBox = ({ onChangeProps, placeholder, onTextChange, ...props }) => {
  return (
    <Input
      style={{ backgroundColor: '#f2f2f2', height: 40 }}
      placeholder={placeholder}
      size="large"
      className="mv-search-box"
      allowClear={true}
      onKeyDown={onChangeProps}
      onChange={onTextChange}
      {...props}
    />
  );
};

export const CoreSearchBtn = ({ onClick, ...props }) => {
  return (
    <Button
      size="large"
      shape="round"
      type="primary"
      {...props} onClick={onClick}>Search</Button>
  )
}

export const LicensePlateInput = ({ value = '', onChange, ...inputProps }) => {
  const { bgcolor } = inputProps;
  const handleChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.toUpperCase();
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2).replace(/[^0-9]/g, '');
    } else {
      inputValue = inputValue.replace(/[^A-Z0-9]/g, ''); 
    }
    onChange(inputValue);
  };

  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorPrimaryHover: '#f5f5f5',
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        Input: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        },
        InputNumber: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        }
      },
    }}>
    <Input
      size="large"
      maxLength={7}
      value={value}
      onChange={handleChange}
      placeholder="LicensePlateNumber"
    />
    </ConfigProvider>
  );
};

