import { DeleteOutlined, EditOutlined, FastForwardFilled, PlusCircleFilled } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Row, Skeleton, Space, Table } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApiInstance } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import Api from '../../network/api';
import { CompanyTitle, TitleLevel3 } from '../../components/general-component';
import { CoreSearchBox, CreateButton } from '../../components/core-components';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../network/withRouter';
import { CustomPath } from '../../routes/custom-path';
import { Constant } from '../../core/constant';

class VehiclePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteItem: "",
            searchValue: [],
            data_filter_params: '',
            prevSearchTerm: '',
            data: [],
            companyName: '',
            page: Constant.page,
            pageSize: Constant.pageSize,
            totalPagination: '',
            isDataFetching : true,
            columns: [
                {
                    title: 'No.',
                    key: 'index',
                    render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
                    width: 5
                },
                {
                    title: i18next.t('license_plate_number'),
                    dataIndex: 'licensePlateNumber',
                    key: '1',
                },
                {
                    title: i18next.t('model'),
                    dataIndex: 'model',
                    key: '3',
                },
                {
                    title: i18next.t('color'),
                    dataIndex: 'color',
                    key: '5',
                },
                {
                    title: i18next.t('note'),
                    dataIndex: 'note',
                    key: '8',
                },
                {
                    title: i18next.t('action'),
                    dataIndex: '',
                    textAlign: 'middle',
                    width: 60,
                    key: 'action',
                    render: (vehicle) => (
                        <Space>
                            <Link to={`${CustomPath.vehicle_update}/${vehicle.id}`}>
                                <Button size={'middle'} type="primary" shape="circle">
                                    <EditOutlined />
                                </Button>
                            </Link>
                        </Space>
                    )
                }
            ],
            items: [
                {
                    title: "Home",
                },
                {
                    title: 'Vehicle',
                },
            ],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async (pageNumber) => {
        const { page, user_filter_params } = this.state;

        const params = {
            ...user_filter_params,
            page: pageNumber ? pageNumber - 1 : page - 1,
        };

        await ApiInstance(Api.vehicle_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            .then(response => {
                this.setState({
                    data: response?.content,
                    totalPagination: response?.totalElements,
                    page: pageNumber ? pageNumber : page,
                    isDataFetching: false
                })
            })
            .catch(error => {
                this.setState({
                })
            })

    }

    search = async () => {
        const { page, pageNumber, searchTerm, data_filter_params, companyId } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                companyId: companyId,
                page: pageNumber ? pageNumber - 1 : page - 1,
            };

            const response = await ApiInstance(Api.vehicle_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event, searchName) => {
        let { data_filter_params } = this.state;

        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value,
        }
        console.log(data_filter_params);
        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );
        }
    }

    handleKeyDown = (e) => {
        this.search();
    };


    render() {
        const { page, pageSize, totalPagination, isDataFetching } = this.state
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <TitleLevel3 label={i18next.t("vehicle")} />
                    </Col>
                    <Col span={16} style={{ textAlign: "end" }}>
                        <CreateButton path={CustomPath.vehicle_create} icon={<PlusCircleFilled />} />
                    </Col>
                    <Col span={4}><CoreSearchBox
                        size="middle"
                        onChange={(values) =>
                            this.changeSearchName(values, "licensePlateNumber")}
                        placeholder="SearchByLicenseNumber"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={20}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
                    </Col>
                    <Col span={24}>
                    {
                            isDataFetching ?
                                <Skeleton active />
                                :
                        <Table pagination={{
                            position: "top" | "bottom",
                            total: totalPagination,
                            current: page,
                            onChange: this.handlePaginationChange,
                            defaultPageSize: pageSize,
                        }}
                            dataSource={this.state.data} columns={this.state.columns} />
                    }
                    </Col>
                </Row>
            </>
        )
    }
}
export default compose(withRouter(VehiclePage)) 