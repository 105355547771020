const data = {
    borderRadius: 6,
    colorPrimary: '#1677ff',
    Button: {
        colorPrimary: '#1677ff',
    },
};



export const theme = {
    token: {
        colorPrimary: data.colorPrimary,
        borderRadius: data.borderRadius,
        colorBgSider: '#fffeee',
        colorBgContent: '#f5f5f5',
    },
    components: {
        Button: {
            colorPrimary: data.Button.colorPrimary,
            algorithm: data.Button?.algorithm,
        },
        Content: {
            colorBgContainer:'#f5f5f5',
            colorLgContainer:"#ffffff"
        },
        Header: {
            colorPrimary:'black',
            colorBgContainer:'#ffffff',
        },
        Layout: {
            Content: {
            colorBgContainer:'#f5f5f5'
        }
    },
        Input: {
            colorBgContainer:'#f5f5f5',
            border:'none',
            boxShadow:'none'
        },
        Select: {
            colorBgContainer:"#f5f5f5",
        },
        DatePicker : {
            colorBgContainer: '#f5f5f5',
            border: 'none',
        }
    },
}