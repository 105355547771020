import { ConfigProvider } from "antd";
import { createBrowserHistory } from 'history';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from 'react-i18next';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { theme } from "./components/assets/theme";
import "./index.css";
import store, { persistor } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import RouteConnect from "./routes/custom-router";

// Create a history object
const history = createBrowserHistory();


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr'],
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });
const loadingMarkup = (
  <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  </div>
)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={loadingMarkup}>
    <ConfigProvider theme={theme}>
    <BrowserRouter history={history}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouteConnect />
        </PersistGate>
      </Provider>
    </BrowserRouter>
    </ConfigProvider>
  </Suspense>

)
reportWebVitals();
