import { Button, Card, DatePicker } from "antd";
import React from "react";
import { MvCard } from "../../components/core-components";


const { RangePicker } = DatePicker;
function DailyReport() {
  return (
    <div>
      <div>
        <RangePicker
          style={{ width: "50%", fontSize: "1.4em", fontWeight: "bold" }}
        />
        <Button>Search</Button>
      </div>
      <MvCard text={"48000"} title={"Main Points"} />
      <MvCard title={"Distances Travelled"} text={"2.4 KM"} />
    </div>
  );
}

export default DailyReport;
