import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    return (
        <WrappedComponent
            {...props}
            params={params}
            navigate={navigate}
            location={location}
            dispatch={dispatch}
        />
    );
};
export default withRouter;