import React, { Component } from 'react';
import { Select, Input } from 'antd';
import { getNrcStates, getNrcTownshipsByStateId } from 'mm-nrc';
import { CoreInput, CoreSelect } from '../core-components';

const { Option } = Select;

class CoreNRCInput extends Component {
  state = {
    states: [],
    townships: [],
    selectedState: '',
    selectedTownship: '',
    selectedType: '',
    nrcNumber: '',
  };

  componentDidMount() {
    const statesData = getNrcStates();
    this.setState({ states: statesData }, this.setInitialValues);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setInitialValues();
    }
  }

  setInitialValues = () => {
    const { value } = this.props;
  
    let state = '';
    let township = '';
    let type = '';
    let number = '';
  
    if (value) {
      // Assuming the format is `1dzIVEs684iyjetc/BAW GA LI(N)334563`
      const parts = value.split('/');
      if (parts.length > 1) {
        state = parts[0].trim(); // State part
  
        const rest = parts[1].split('(');
        if (rest.length > 1) {
          township = rest[0].trim(); // Township part
  
          const typeAndNumber = rest[1].replace(')', '').trim(); // Remove closing ')' and trim spaces
  
          // Regex to extract type (N or E) and number
          const match = typeAndNumber.match(/^([NE])(\d*)$/);
  
          if (match) {
            type = match[1]; // Type (N or E)
            number = match[2] ? match[2].replace(/[^0-9]/g, '').slice(0, 6) : ''; // Number
          }
        }
      }
    }
  
    // Ensure type is either "N" or "E"
    const validType = type === 'N' || type === 'E' ? type : '';
  
    this.setState({
      selectedState: state,
      selectedTownship: township,
      selectedType: validType,
      nrcNumber: number,
    });
  
    // Fetch and set townships based on the selected state
    const townshipsData = getNrcTownshipsByStateId(state);
    this.setState({ townships: townshipsData });
  };

  handleStateChange = (value) => {
    this.setState({ selectedState: value }, () => {
      const townshipsData = getNrcTownshipsByStateId(value);
      this.setState({ townships: townshipsData }, () => {
        this.updateFormattedNrc(value, this.state.selectedTownship, this.state.selectedType, this.state.nrcNumber);
      });
    });
  };

  handleTownshipChange = (value) => {
    this.setState({ selectedTownship: value }, () => {
      this.updateFormattedNrc(this.state.selectedState, value, this.state.selectedType, this.state.nrcNumber);
    });
  };

  handleTypeChange = (value) => {
    this.setState({ selectedType: value }, () => {
      this.updateFormattedNrc(this.state.selectedState, this.state.selectedTownship, value, this.state.nrcNumber);
    });
  };

  handleNrcNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
    this.setState({ nrcNumber: value }, () => {
      this.updateFormattedNrc(this.state.selectedState, this.state.selectedTownship, this.state.selectedType, value);
    });
  };

  updateFormattedNrc = (state, township, type, number) => {
    const formattedNrc = `${state}/${township}(${type})${number}`;
    this.props.onChange(formattedNrc);
  };

  render() {
    const { states, townships, selectedState, selectedTownship, selectedType, nrcNumber } = this.state;

    return (
      <div>
        <CoreSelect
          style={{ width: 120, marginRight: '10px' }}
          placeholder="State"
          value={selectedState}
          onChange={this.handleStateChange}
        >
          {states.map((state) => (
            <Option key={state.id} value={state.en}>{state.code}</Option>
          ))}
        </CoreSelect>
        <CoreSelect
          style={{ width: 120, marginRight: '10px' }}
          placeholder="Township"
          value={selectedTownship}
          onChange={this.handleTownshipChange}
          disabled={!selectedState}
        >
          {townships.map((township) => (
            <Option key={township.id} value={township.code}>{township.code}</Option>
          ))}
        </CoreSelect>
        <CoreSelect
          style={{ width: 120, marginRight: '10px' }}
          placeholder="Type"
          value={selectedType}
          onChange={this.handleTypeChange}
        >
          <Option value="N">N</Option>
          <Option value="E">E</Option>
        </CoreSelect>
        <CoreInput
          style={{ width: 120, marginRight: '10px' }}
          placeholder="Number"
          value={nrcNumber}
          onChange={this.handleNrcNumberChange}
        />
      </div>
    );
  }
}

export default CoreNRCInput;