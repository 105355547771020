import { EditOutlined, PlusCircleFilled } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Input, InputNumber, message, Row, Skeleton, Space, Table, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { CoreSearchBox, CreateButton } from '../../components/core-components';
import { TitleLevel3 } from '../../components/general-component';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import { CustomPath } from '../../routes/custom-path';
import { Constant } from '../../core/constant';

class FuelStationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteItem: "",
      data: [],
      page: Constant.page,
      pageSize: Constant.pageSize,
      pageNumber: 0,
      totalPagination: 0,
      searchValue: [],
      data_filter_params: '',
      prevSearchTerm: '',
      pageNumber: '',
      columns: [
        {
          title: 'No.',
          key: 'index',
          render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
          width: 5
        },
        {
          title: i18next.t('fuel_station_name'),
          dataIndex: 'stationName',
          key: '1',
        },
        {
          title: i18next.t('owner_name'),
          dataIndex: ['company', 'ownerName'],
          key: '2',
        },
        {
          title: i18next.t('phone_number'),
          dataIndex: 'phoneNumber',
          key: '3',
        },
        {
          title: 'Points',
          dataIndex: 'fuelPoint',
          key: 'fuelPoint'
        },
        {
          title: i18next.t('fuel_staff'),
          dataIndex: '',
          key: '4',
          render: (station) => (
            <Link to={`${CustomPath.station}/${station.id}/${CustomPath.staff}`}><a>{i18next.t("view_staff")}</a></Link>
          )
        },
        {
          title: i18next.t('status'),
          dataIndex: 'status',
          key: '5',
          render: (status) => (
            <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>
              {status}
            </Tag>
          ),
        },
        {
          title: i18next.t('action'),
          dataIndex: '',
          key: '6',
          width: 60,
          textAlign: 'center',
          render: (station) => (
            <Space>
              <Link to={`${CustomPath.station_update}/${station.id}`}>
                <Button size={"medium"} type="primary" shape="circle">
                  <EditOutlined />
                </Button>
              </Link>
            </Space>
          )
        },
      ],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (pageNumber) => {
    const { page, user_filter_params } = this.state;

    const params = {
      ...user_filter_params,
      page: pageNumber ? pageNumber - 1 : page - 1,
    };
    await ApiInstance(Api.station_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      .then(response => {
        this.setState({
          data: response?.content,
          totalPagination: response?.totalElements,
          page: pageNumber ? pageNumber : page,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          // loading: false
        })
      })
  }

  search = async () => {
    const { page, pageNumber, searchTerm, data_filter_params, companyId } = this.state
    this.setState({
      isDataFetching: true
    })
    try {
      const params = {
        ...data_filter_params,
        companyId: companyId,
        page: pageNumber ? pageNumber - 1 : page - 1,
      };

      const response = await ApiInstance(Api.station_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: page,
          prevSearchTerm: searchTerm
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false
    })
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  changeSearchName = (event, searchName) => {
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      [searchName]: event.target.value,
    }
    console.log(data_filter_params);
    this.setState({
      data_filter_params: data_filter_params,
      searchTerm: event.target
    });

    if (event.target.value === '' && this.state.prevSearchTerm) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          [searchName]: '',
        },
        prevSearchTerm: ''
      }, () =>
        this.search()
      );
    }
  }

  handleKeyDown = (e) => {
    this.search();
  };

  handleInputChange = (e, id) => {
    const { value } = e.target;
    this.setState(prevState => ({
      deductPoints: {
        ...prevState.deductPoints,
        [id]: value,
      },
      points: e.target.value
    }));
  };

  handleDeductPoints = async (id) => {
    const { points, deductPoints } = this.state

    this.setState({
      on_fill_loading: true
    })

    try {

      const response = await ApiInstance(
        Api.deduct_point + `&fuelPoint=` + points, HTTP_METHOD.PUT, MEDIA_TYPE.JSON,);

      if (response.status == 200) {
        message.success(response.data.message)
        this.fetchData();
        this.setState({
          on_fill_loading: false,

        })
      }
      this.setState({
        deductPoints: {
          ...deductPoints,
          [id]: '',
        },
      });
    } catch (error) {
      console.log(error);

      this.setState({
        on_fill_loading: false
      })
    }

  }

  render() {
    const { loading, data, columns, totalPagination, pageSize, page } = this.state;

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <TitleLevel3 label={i18next.t("fuel_station")} />
          </Col>
          <Col span={16} style={{ textAlign: "end" }}>
            <CreateButton path={CustomPath.station_create} />
          </Col>
          <Col span={4}><CoreSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "stationName")}
            placeholder="SearchByStationName"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}><CoreSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "ownerName")}
            placeholder="SearchByOwnerName"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}><CoreSearchBox
            size="middle"
            onChange={(values) => this.changeSearchName(values, "phoneNumber")}
            placeholder="SearchByPhoneNumber"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={12}>
            <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
          </Col>
          <Col span={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <Table
                pagination={{
                  position: "top" | "bottom",
                  total: totalPagination,
                  current: page,
                  onChange: this.handlePaginationChange,
                  defaultPageSize: pageSize,
                }}
                dataSource={data} columns={columns} />
            )}
          </Col>
        </Row>
      </>
    )
  }
}
export default compose(withRouter)(FuelStationPage)