import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CoreBackButton, CoreCreateButton, LicensePlateInput } from '../../components/core-components';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import { CoreInput } from '../../components/core-components';
import { CustomPath } from '../../routes/custom-path';
import { validateLicensePlate } from '../../core/constant';


export class VehicleCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on_create_loading: false,
      items: [
        {
          title: "Home",
        },
        {
          title: <Link to={CustomPath.vehicle}>{i18next.t("vehicle")}</Link>,
        },
        {
          title: i18next.t("vehicle.form"),
        },
      ],
    }
  }

  saveVehicle = async (values) => {

    this.setState({
      on_create_loading: true
    })

    const requestData = {
      "licensePlateNumber": values.licensePlateNumber,
      "made": values.made,
      "model": values.model,
      "modelYear": values.modelYear,
      "color": values.color,
      "enginePower": values.enginePower,
      "estimateFuelConsumption": values.estimateFuelConsumption,
      "note": values.note,
      "status": values.status,
      "ownershipStatus": values.ownershipStatus,
    }

    try {
      const response = await ApiInstance(
        Api.vehicle, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.props.navigate(CustomPath.vehicle)
      }
    } catch (error) {
      console.log("error in save user");
      console.log(error);
    }
    this.setState({
      on_create_loading: false
    })
  };


  render() {
    const { items, on_create_loading } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Breadcrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              style={{ marginBottom: "20px" }}
              className="form"
              title={<h3>{i18next.t("vehicle.form")}</h3>}
              bordered={false}
              extra={
                <CoreBackButton
                  path={CustomPath.vehicle}
                  type="primary"
                  text={i18next.t("back")}
                />
              }
            >
              <Form
                layout="vertical"
                onFinish={this.saveVehicle}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="licensePlateNumber"
                      label={i18next.t('license_plate_number')}
                      rules={[{ required: true, validator: validateLicensePlate }]}
                    >
                      <LicensePlateInput />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="made"
                      label={i18next.t("made")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("made")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="model"
                      label={i18next.t("model")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("model")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="modelYear"
                      label={i18next.t("model_year")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("model_year")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="color"
                      label={i18next.t("color")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("color")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="enginePower"
                      label={i18next.t("engine_power")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput size="large" placeholder={i18next.t("engine_power")} />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="estimateFuelConsumption"
                      label={i18next.t("estimate_fuel_consumption")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("estimate_fuel_consumption")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="ownershipStatus"
                      label={i18next.t("ownership_status")}
                    >
                      <Radio.Group>
                        <Radio value="OWN_BY_COMPANY">{i18next.t("own_by_company")}</Radio>
                        <Radio value="OWN_BY_DRIVER">{i18next.t("own_by_driver")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item name="note" label={i18next.t("note")}>
                      <CoreInput
                        isTextareaInput={true}
                        style={{
                          backgroundColor: '',
                          border: "",
                        }}
                        placeholder={i18next.t('note')}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <CoreCreateButton loading={on_create_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter(VehicleCreate));