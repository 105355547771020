import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import { LOC_getAuthenticate, LOC_store_token } from "../core/custom-local-storage";
import FuelPointHistory from "../pages/companyfuelPointHistory/fuel-point-history";
import DriverCreate from "../pages/driver/driver-create";
import DriverPage from "../pages/driver/driver-page";
import DriverUpdate from "../pages/driver/driver-update";
import StationPointHistory from "../pages/fuelStationPointHistory/fuel-point-history";
import Login from "../pages/login/Login";
import DailyReport from "../pages/report/daily-report";
import FuelPointRequest from '../pages/fuelPointRequests/fuel-point-requests'
import StaffCreate from "../pages/staff/staff-create";
import StaffPage from "../pages/staff/staff-page";
import StaffUpdate from "../pages/staff/staff-update";
import FuelStationCreate from '../pages/station/station-create';
import FuelStationPage from '../pages/station/station-page';
import FuelStationUpdate from '../pages/station/station-update';
import VehicleCreate from "../pages/vehicle/vehicle-create";
import VehiclePage from "../pages/vehicle/vehicle-page";
import VehicleUpdate from "../pages/vehicle/vehicle-update";
import { CustomPath } from "./custom-path";


function RouteConnect() {

  const userType = useSelector((state) => state.userType.value);
  console.log(LOC_store_token())
  console.log(LOC_getAuthenticate())

  return (

    <Routes>
      {
        LOC_getAuthenticate() ? (
          <>
            {userType === 'FUEL_MANAGER' &&
              <>
                <Route path="" element={<App />}>
                  <Route path="/" element={<StaffPage />} />
                  <Route path={CustomPath.staff} element={<StaffPage />} />
                  <Route path={CustomPath.staff_create} element={<StaffCreate />} />
                  <Route path={CustomPath.staff_update_param} element={<StaffUpdate />} />
                </Route>
              </>
            }
            {userType === 'FUEL_ADMIN' &&
              <>
                <Route path="/" element={<App />}>
                  <Route path="/" element={<FuelStationPage />} />
                  <Route path={CustomPath.station} element={<FuelStationPage />} />
                  <Route path={CustomPath.station_create} element={<FuelStationCreate />} />
                  <Route path={CustomPath.station_update_param} element={<FuelStationUpdate />} />
                  <Route path={CustomPath.staff_param} element={<StaffPage />} />
                  <Route path={CustomPath.staff_create_param} element={<StaffCreate />} />
                  <Route path={CustomPath.staff_update_param} element={<StaffUpdate />} />
                  <Route path={CustomPath.daily_report} element={<DailyReport />} />
                  <Route path={CustomPath.fuel_point_history} element={<StationPointHistory />} />
                </Route>
              </>
            }
            {userType === 'COMPANY_ADMIN' &&
              <>
                <Route path="/" element={<App />}>
                  <Route path="/" element={<DriverPage />} />
                  <Route path={CustomPath.driver} element={<DriverPage />} />
                  <Route path={CustomPath.driver_create} element={<DriverCreate />} />
                  <Route path={CustomPath.driver_update_param} element={<DriverUpdate />} />

                  <Route path={CustomPath.vehicle} element={<VehiclePage />} />
                  <Route path={CustomPath.vehicle_create} element={<VehicleCreate />} />
                  <Route path={CustomPath.vehicle_update_param} element={<VehicleUpdate />} />

                  <Route path={CustomPath.point_requests} element={<FuelPointRequest />} />
                  <Route path={CustomPath.fuel_point_history} element={<FuelPointHistory />} />
                  {/* <Route path={CustomPath.station_point_history} element={<StationPointHistory />} /> */}
                </Route>
              </>
            }
          {userType === null && 
            <Route path='/*' element={<Login />} />
          }
          
          </>
        ) : <>
          
          <Route path="/*" element={<Login />} />
          </>
      }
      <Route path={CustomPath.login} element={<Login />} />
    </Routes>
  );
}

export default RouteConnect;







{/* <Route path="branch" element={<BranchInfo />} />
        <Route path="branchCreate" element={<BranchCreate />} />
        <Route path="branchUpdate" element={<BranchUpdate />} />
        <Route path="dailyReport" element={<DailyReport />} /> */}