import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Button, Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React from 'react';
import { Link } from 'react-router-dom';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import { CustomPath } from '../../routes/custom-path';
import { CoreBackButton, CoreCreateButton, CoreInput } from '../../components/core-components';
import { validatePhoneNumber } from '../../core/constant';

class FuelStationCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            on_create_loading: false,
            items: [
                {
                    title: i18next.t("home"),
                },
                {
                    title: <Link to={CustomPath.station}>{i18next.t("fuel_station")}</Link>,
                },
                {
                    title: i18next.t("fuel_station.form")
                },
            ]
        }
    }

    saveFuelStation = async (values) => {

        this.setState({
            on_create_loading: true
        })

        console.log("values:", values);

        const requestData = {
            "stationName": values.stationName,
            "phoneNumber": values.phoneNumber,
            "address": values.address,
            "latitude": values.longitude,
            "longitude": values.latitude,
            "status": values.status,
        }

        try {
            const response = await ApiInstance(
                Api.station, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
            if (response.status == 200) {
                this.props.navigate(CustomPath.station)
            }
        } catch (error) {
            console.log("error in save user");
            console.log(error);
        }

        this.setState({
            on_create_loading: false
        })
    };
    render() {
        const { on_create_loading } = this.state;
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Breadcrumb items={this.state.items} />
                    </Col>
                    <Col span={24}>
                        <Card
                            style={{ marginBottom: "20px" }}
                            className="form"
                            title={<h3>{i18next.t("fuel_station.form")}</h3>}
                            bordered={false}
                            extra={
                                <CoreBackButton
                                    path={CustomPath.station}
                                    type="primary"
                                    text={i18next.t("back")}
                                />
                            }
                        >
                            <Form
                                layout="vertical"
                                onFinish={this.saveFuelStation}
                            >
                                <Row gutter={24}>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="stationName"
                                            label={i18next.t('fuel_station_name')}
                                            rules={[{ required: true, message: "(*required)" }]}
                                        >
                                            <CoreInput
                                                size="large"
                                                placeholder={i18next.t("fuel_station_name")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="phoneNumber"
                                            label={i18next.t("phone_number")}
                                            rules={[
                                                {required: true, validator: validatePhoneNumber }
                                              ]}
                                        >
                                            <CoreInput
                                                size="large"
                                                placeholder={i18next.t("phone_number")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="longitude"
                                            label={i18next.t("longitude")}
                                            rules={[{ required: true, message: "(*required)" }]}
                                        >
                                            <CoreInput size="large" placeholder={i18next.t("longitude")} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="latitude"
                                            label={i18next.t("latitude")}
                                            rules={[{ required: true, message: "(*required)" }]}
                                        >
                                            <CoreInput size="large" placeholder={i18next.t("latitude")} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="status"
                                            label={i18next.t("status")}
                                        >
                                            <Radio.Group>
                                                <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                                                <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 24 }}>
                                        <Form.Item style={{ textAlign: "end" }}>
                                            <CoreCreateButton loading={on_create_loading} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}
export default compose(withRouter(FuelStationCreate)) 