export const Constant = {
    pageSize: 20,
    page: 1
}

export const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^09\d{7,10}$/;
  
    if (!value) {
      return Promise.reject('Phone number is required');
    }
    if (!phoneNumberRegex.test(value)) {
      return Promise.reject('Phone number must start with 09 and be 10 digits long');
    }
    return Promise.resolve();
  };

export  const validateLicensePlate = (_, value) => {
    if (!value || value.match(/^.{2}-\d{4}$/)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid license plate format'));
  };