import { message } from 'antd';
import axios from 'axios';
import { LOC_getRefreshToken } from '../core/custom-local-storage';
import Api from '../network/api';
import { updateAccessToken } from '../redux/authTokenSlice';
import { CustomPath } from '../routes/custom-path';
import { createBrowserHistory } from 'history';

const axiosInstance = axios.create({
    baseURL: Api.host
});

const history = createBrowserHistory();

export const Authenticate = async (username, password ) => {
    try {
        const response = await axiosInstance.post(Api.login, {
            "username": username,
            "password": password
        }, { headers: { accountType: "PORTAL_USER" } })
        return response
    } catch (error) {
        return error
    }
}

export const RefreshToken = async (props) => {
    try {
        const refreshToken = LOC_getRefreshToken()
        const response = await axiosInstance.post(Api.refresh_token, {
            "refreshToken": refreshToken
        })
        if (response && response.status === 200) {
            props.dispatch(updateAccessToken(response.data))
            return response.data
        }else
        if (response && response.status === 401) {
            history.push('/login');
            return response.data
        }
    } catch (error) {
        message.destroy()
        message.error("Please Login Again!")
        history.push('/login');
        window.location.reload();
    }
}  