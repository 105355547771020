import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, message } from "antd";

export const TitleLevel3 = (props) => {
    const { label } = props;
    return (
        <span style={{ fontSize: 27, fontWeight: '600' }}>{label}</span>
    )
}

export const notiError = (content) => {
    message.error({
        content: content,
        className: 'custom-error-message', // Optional custom class
        style: {
            borderRadius: '8px', // Rounded corners
            textAlign: 'end',
            width: 'auto',
            fontSize: '15px',
            // padding: '25px 25px',
        },
        duration: 2
    });
}

export const Logout = ({ onClick, icon, text }) => {
    const items = [
      {
        key: '1',
        label: (
          <a  onClick={onClick}>
            Logout
          </a>
        ),
      },
    ];
  
    return (
      <>
      <Dropdown
          menu={{
            items,
          }}
          placement="bottom"
          arrow
        >
          <a type="primary" icon={icon} className="avatar-button">
            <Avatar icon={<UserOutlined />} style={{ marginRight: 3 }} />
            {text}
        </a>
        </Dropdown>
        </>
        
    );
  };

  export const CompanyTitle = (props) => {
    const { label } = props;
    return(
        <span style={{fontWeight:'bold', fontSize:'25px', color:'#16007a', marginBottom:'20px'}}>{label}</span>
    )
}