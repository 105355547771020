import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { compose, current } from "@reduxjs/toolkit";
import { Button, Col, DatePicker, Modal, Row, Select, Skeleton, Space, Table } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { Option } from "antd/es/mentions";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { CustomPath } from "../../routes/custom-path";
import { CoreSearchBox } from "../../components/core-components";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiInstance } from "../../network/network-manager";
import { TitleLevel3 } from "../../components/general-component";

const { RangePicker } = DatePicker;

class StationPointHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stationPage: 0,
            searchTerm: '',
            data: [],
            isDataFetching: false,
            prevSearchTerm: '',
            isSearching: false,
            page: Constant.page,
            pageSize: Constant.pageSize,
            pageNumber: 0,
            totalPagination: 0,
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            searchValue: [],
            searchField: [
                { field: 'fromUserName', value: '' },
                { field: 'toUserName', value: '' },
                { field: 'points', value: '' },
                { field: 'date', value: '' },
            ],
            data_filter_params: {
                'fromUserName': '', 'toUserName': '', 'points': '', 'fromDate': null,
                'toDate': null,
            },
            stationValue: '',
            totalStation: [{ stationName: 'ALL' }],
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchStation()
    }

    fetchData = async (pageNumber, value) => {
        const { page, searchValue, pageSize, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                page: pageNumber ? pageNumber - 1 : page - 1,
            };
            const response = await ApiInstance(Api.fuel_point_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
            searchValue: value ? value : searchValue
        })
    }

    changeSearchName = (event, searchName) => {
        let { data_filter_params, searchField } = this.state;

        const updatedSearchValues = searchField.map((item) => {
            if (item.field === searchName) {
                return { ...item, value: searchName };
            }
            return item;
        });


        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value
        };

        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target,
            page: 1,
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );

        }
    }

    search = async () => {
        const { data_filter_params, page, pageSize, searchTerm } = this.state

        this.setState({
            isDataFetching: true
        })

        const params = {
            ...data_filter_params,
        };

        try {
            const response = await ApiInstance(Api.fuel_point_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    handleKeyDown = (e) => {
        this.search();
    };

    handleDateChange = (dates) => {
        const { data_filter_params, pagination } = this.state

        const fromDate = dates ? dates[0].format('YYYY-MM-DDTHH:mm:ss') : null;
        const toDate = dates ? dates[1].format('YYYY-MM-DDTHH:mm:ss') : null;

        this.setState(
            {
                data_filter_params: {
                    ...data_filter_params,
                    fromDate,
                    toDate,
                    page: 0,
                },
                pagination: {
                    ...pagination,
                    current: 1,
                },
                page: 1,
                current: 1
            },
            () => {
                this.search();
            }
        );
    };



    fetchStation = async (id) => {
        const { all, companyId, data_filter_params, stationPage } = this.state

        const params = {
            // ...data_filter_params,
            page: stationPage
            // companyId: id,
        };
        await ApiInstance(Api.station_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            .then(response => {
                this.setState((prevState) => ({
                    totalStation: [...prevState.totalStation, ...response.content],
                    loading: false,
                    hasMore: response.content.length >= 20
                }));
            })
            .catch(error => {
                this.setState({

                })
            })
    }

    filterByStation = (value, option) => {
        let { data_filter_params } = this.state;

        data_filter_params = {
            ...data_filter_params,
            fuelStationId: value
        }
        this.setState({
            data_filter_params: data_filter_params,
            stationId: value,
        }, () => this.search()
        )

    }

    stationPopupScroll = (e) => {
        const { hasMore } = this.state

        const { target } = e;
        const buffer = 10;
        if (target.scrollTop + target.offsetHeight + buffer >= target.scrollHeight && this.state.hasMore && !this.state.loading) {
            this.setState((prevState) => ({ stationPage: prevState.stationPage + 1 }), () => {
                this.fetchStation(null, this.state.stationPage);
            });
        }
    }

    filterByHistoryType = (value, option) => {

        const { data_filter_params, stationId } = this.state;

        let params;

        params = {
            ...data_filter_params,
            historyType: value === "ALL" ? '' : value,
            fuelStationId: stationId
        }

        this.setState({
            data_filter_params: params,
            page: 1,
            current: 0
        }, () => this.search()
        )
    }


    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching, stationValue, totalStation } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: i18next.t('from_user'),
                dataIndex: ['fromUser', 'fullName'],
                key: 'admin',
            },
            {
                title: i18next.t('to_user'),
                dataIndex: ['toUser', 'fullName'],
                key: 'driver',
            },
            {
                title: i18next.t('points'),
                dataIndex: 'fuelPoint',
                key: 'fuelPoint',
            },

            {
                title: i18next.t('date'),
                dataIndex: 'createdDate',
                key: 'createdDate',
            },
            {
                title: i18next.t('history_type'),
                dataIndex: 'historyType',
                key: 'historyType',
            },
        ]
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TitleLevel3 label={i18next.t("fuel_point_history")} />
                    </Col>
                    <Col span={4}>
                        <CoreSearchBox
                            size="middle"
                            onPressEnter={this.handleKeyDown}
                            onChange={(values) => this.changeSearchName(values, "fromUserName")}
                            placeholder="SearchByFromUser" />
                    </Col><Col span={4}><CoreSearchBox
                        size="middle"
                        onChange={(values) => this.changeSearchName(values, "toUserName")}
                        placeholder="SearchByToUser"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={7}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >{i18next.t('search')}</Button>
                    </Col>
                    <Col span={5}>
                        <RangePicker
                            format="YYYY-MM-DD"
                            onChange={this.handleDateChange}
                            style={{ height: '40px' }}
                        />
                    </Col>
                    <Col span={4} style={{ textAlign: 'end' }}>
                        <Select
                            showSearch
                            style={{ height: 40, width: 170, textAlign: 'start' }}
                            placeholder="Filter By Station"
                            onChange={this.filterByStation}
                            dropdownStyle={{ width: 300, maxHeight: 350, fontSize: 40 }}
                            // maxTagCount={40}
                            listHeight={350}
                            onPopupScroll={this.stationPopupScroll}
                        >
                            {totalStation.map(option => (
                                <Select.Option style={{ height: 40, fontSize: 15 }} key={option.id}>
                                    {option.stationName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={24} style={{ textAlign: 'end' }}>
                        <Select
                            showSearch
                            style={{ height: 40, width: 170, textAlign: 'start' }}
                            placeholder="Filter By HistoryType"
                            onChange={this.filterByHistoryType}
                            options={[
                                {
                                    value: '',
                                    label: 'ALL',
                                },
                                {
                                    value: 'EXCHANGE',
                                    label: 'EXCHANGE',
                                },
                                {
                                    value: 'USED',
                                    label: 'USED',
                                },
                            ]}
                        >

                        </Select>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    pagination={{
                                        position: "top" | "bottom",
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                    }}

                                    style={{ borderRadius: 10 }}
                                    columns={columns} dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                </Row >

            </>
        );
    }
}

export default StationPointHistory