export const SvgStaff = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 32 30" width={width} height={height} fill={color}><path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z" /></svg>
}

export const SvgDriver = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 28 25" width={width} height={height} fill={color}><path d="M12,0A12.013,12.013,0,0,0,0,12c.6,15.9,23.4,15.893,24,0A12.013,12.013,0,0,0,12,0Zm0,2a10.008,10.008,0,0,1,9.3,6.338L14.024,9.793a10.705,10.705,0,0,1-4.19,0L2.694,8.365A10.009,10.009,0,0,1,12,2Zm-10,10.107,4.155.831a4,4,0,0,1,2.733,2.018l.625,1.153a4.006,4.006,0,0,1,.482,1.9V21.8A10.014,10.014,0,0,1,2.005,12.107ZM14,21.8V18.013a4.006,4.006,0,0,1,.482-1.9l.625-1.153a4,4,0,0,1,2.733-2.018L22,12.107A10.014,10.014,0,0,1,14,21.8Z" /></svg>
}

export const SvgVehicle = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" id="truck" width={width} height={height} fill={color}><g><g><rect width="24" height="24" opacity="0"></rect><path d="M21.6 11.22L17 7.52V5a1.91 1.91 0 0 0-1.81-2H3.79A1.91 1.91 0 0 0 2 5v10a2 2 0 0 0 1.2 1.88 3 3 0 1 0 5.6.12h6.36a3 3 0 1 0 5.64 0h.2a1 1 0 0 0 1-1v-4a1 1 0 0 0-.4-.78zM20 12.48V15h-3v-4.92zM7 18a1 1 0 1 1-1-1 1 1 0 0 1 1 1zm5-3H4V5h11v10zm7 3a1 1 0 1 1-1-1 1 1 0 0 1 1 1z"></path></g></g></svg>
}

export const SvgStation = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 512 512" viewBox="0 0 580 500" id="gas-station" width={width} height={height} fill={color}><path d="M301.6,115.2H135.8c-16.9,0-30.6,13.7-30.6,30.6v86.9c0,16.9,13.7,30.6,30.6,30.6h165.8c16.9,0,30.6-13.7,30.6-30.6v-86.9C332.2,129,318.4,115.2,301.6,115.2z M310.8,232.7c0,5.1-4.2,9.3-9.3,9.3H135.8c-5.1,0-9.3-4.2-9.3-9.3v-86.9c0-5.1,4.2-9.3,9.3-9.3h165.8c5.1,0,9.3,4.2,9.3,9.3L310.8,232.7L310.8,232.7z"></path><path d="M463.1,185.8c0.8-30.3,0.2-47.9-17-65.1l-4.6-4.6c-4.2-4.2-10.9-4.2-15.1,0s-4.2,10.9,0,15.1l4.6,4.6c18.5,18.5,7.3,39.6,10.7,122.1c0.1,3.2,1.9,6.3,4.2,8l34.4,26.3v127c0,10.4-8.4,18.8-18.8,18.8s-18.8-8.4-18.8-18.8v-54.6c0-19.8-16.1-35.8-35.8-35.8h-10.7V72.1c17.5-2.5,31.1-17.6,31.1-35.9c0-20-16.3-36.3-36.3-36.3H46.4c-20,0-36.3,16.3-36.3,36.3c0,18.2,13.5,33.3,31.1,35.9v418.5H25.3c-5.9,0-10.7,4.8-10.7,10.7S19.4,512,25.3,512c-5,0,66.7,0,386.8,0c5.9,0,10.7-4.8,10.7-10.7s-4.8-10.7-10.7-10.7h-15.9V350.2h10.7c8,0,14.5,6.5,14.5,14.5v54.6c0,22.1,18,40.1,40.1,40.1s40.1-18,40.1-40.1V257.9C503.9,198.2,474.6,200.8,463.1,185.8z M31.4,36.3c0-8.2,6.7-14.9,14.9-14.9h344.6c8.2,0,14.9,6.7,14.9,14.9s-6.7,14.9-14.9,14.9H46.4C38.1,51.2,31.4,44.5,31.4,36.3z M62.5,490.6v-418h312.3c0,75.8,0,311.4,0,418.1H62.5V490.6z M480.1,265.3l-17-13v-39.6C467.5,220.1,481.8,211.4,480.1,265.3z"></path></svg>
}

export const SvgDailyReport = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 28 26" width={width} height={height} fill={color}><path d="m6.5,16H1C.448,16,0,15.552,0,15s.448-1,1-1h4.965l1.703-2.555c.197-.296.542-.473.894-.443.356.022.673.232.833.551l2.229,4.459,1.044-1.566c.186-.278.498-.445.832-.445h4.5c.552,0,1,.448,1,1s-.448,1-1,1h-3.965l-1.703,2.555c-.186.279-.499.445-.832.445-.021,0-.042,0-.062-.002-.356-.022-.673-.232-.833-.551l-2.229-4.459-1.044,1.566c-.186.278-.498.445-.832.445Zm15.5-5.515v8.515c0,2.757-2.243,5-5,5H7c-2.757,0-5-2.243-5-5,0-.552.448-1,1-1s1,.448,1,1c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3v-8.515c0-.163-.008-.325-.023-.485h-4.977c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023h-4.515c-1.654,0-3,1.346-3,3v6c0,.552-.448,1-1,1s-1-.448-1-1v-6C2,2.243,4.243,0,7,0h4.515c1.87,0,3.627.728,4.95,2.05l3.485,3.485c1.322,1.322,2.05,3.08,2.05,4.95Zm-2.659-2.485c-.218-.379-.487-.733-.805-1.05l-3.485-3.485c-.318-.318-.671-.587-1.05-.805v4.341c0,.551.449,1,1,1h4.341Z" /></svg>
}

export const SvgFuelPointRequests = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 28 26" width={width} height={height} fill={color}><path d="m21,17h-1v-1c0-.552-.447-1-1-1s-1,.448-1,1v1h-5c-1.654,0-3,1.346-3,3v.5c0,.827-.673,1.5-1.5,1.5s-1.5-.673-1.5-1.5V3.5c0-.539-.133-1.044-.351-1.5h2.351c.553,0,1-.448,1-1s-.447-1-1-1H3.5C1.57,0,0,1.57,0,3.5v.5c0,1.654,1.346,3,3,3h2v13.5c0,1.929,1.569,3.499,3.498,3.5h12.002c1.93,0,3.5-1.57,3.5-3.5v-.5c0-1.654-1.346-3-3-3ZM5,5h-2c-.552,0-1-.449-1-1v-.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v1.5Zm17,15.5c0,.827-.673,1.5-1.5,1.5h-8.838c.217-.455.338-.963.338-1.5v-.5c0-.551.448-1,1-1h8c.552,0,1,.449,1,1v.5Zm-5-6.5c3.859,0,7-3.14,7-7S20.859,0,17,0s-7,3.14-7,7,3.141,7,7,7Zm0-12c2.757,0,5,2.243,5,5s-2.243,5-5,5-5-2.243-5-5,2.243-5,5-5Zm-3.482,4.8c.386-.395,1.019-.4,1.414-.015l1.131,1.108c.144.141.379.139.522,0l2.223-2.134c.397-.382,1.032-.369,1.414.029s.369,1.031-.029,1.414l-2.213,2.124c-.453.447-1.052.671-1.653.671s-1.204-.224-1.664-.674l-1.131-1.108c-.395-.387-.4-1.02-.014-1.414Z" />
    </svg>
}

export const SvgPointHistory = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 33 25" width={width} height={color} fill={color}><path d="M12,0A12.034,12.034,0,0,0,4.04,3.04L2.707,1.707A1,1,0,0,0,1,2.414V7A1,1,0,0,0,2,8H6.586a1,1,0,0,0,.707-1.707L6.158,5.158A9,9,0,0,1,21,12.26,9,9,0,0,1,3.1,13.316,1.51,1.51,0,0,0,1.613,12h0A1.489,1.489,0,0,0,.115,13.663,12.018,12.018,0,0,0,12.474,23.991,12.114,12.114,0,0,0,23.991,12.474,12.013,12.013,0,0,0,12,0Z" /><path d="M11.5,7h0A1.5,1.5,0,0,0,10,8.5v4.293a2,2,0,0,0,.586,1.414L12.379,16A1.5,1.5,0,0,0,14.5,13.879l-1.5-1.5V8.5A1.5,1.5,0,0,0,11.5,7Z" /></svg>
}