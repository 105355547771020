import { createSlice } from "@reduxjs/toolkit";

export const authTokenSlice = createSlice({
    name: 'edyaStation',
    initialState : {
        accessToken: '',
        refreshToken: '',
        authenticated : false
    },
    reducers: {
        updateAccessToken: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;

            if(state.accessToken !== null && state.accessToken !== undefined) {
                state.authenticated = true;
            } else {
                state.authenticated = false;
            }
        },
        logoutAndResetReduxState : (state, action) => {
            state.accessToken = '';
            state.refreshToken = '';
            state.authenticated = false
        },
        setAccessToken : (accessToken) => (dispatch) => {
            dispatch(updateAccessToken(accessToken));
            console.log("reduxAccessToken:",accessToken)
          
            // setCookie('access_token', accessToken, 30);
          }
    }
})

export const {
    updateAccessToken,
    logoutAndResetReduxState,
    setAccessToken
} = authTokenSlice.actions;

export const accessToken = (state) => state.auth.accessToken;
export const refreshToken = (state) => state.auth.refreshToken;
export const authenticated = (state) => state.auth.authenticated;
export default authTokenSlice.reducer